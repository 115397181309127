/* Dark mode css */

/* --------Table Content------------ 

     01 Header 
     02 Content

  -------------------- */

/* 01 Header */
.dark-mode header{
    background-color: rgb(4, 28, 50);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.7);
}

.dark-mode ul a{
    color: rgba(255, 255, 255, 0.7);
}

.dark-mode header .headerIcons{
    color: rgba(255, 255, 255, 0.7);
}

.dark-mode .header-offcanvas{
    background-color: rgb(4, 28, 50);
    color: rgba(255, 255, 255, 0.7);
}

.dark-mode .header-offcanvas ul a{
    color: rgba(255, 255, 255, 0.7);
}